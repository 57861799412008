import { useGetMVTSelections } from '../../../hooks';
import { POI_AIRPORT_SHUTTLE_ON_HDP } from '../../../constants';

export const useGetPOIAirportInfoMVT = ({ enableTest }: { enableTest?: boolean }) => {
  const {
    isLoaded: isPOIAndAirportTestLoaded,
    isMVTVariant: { b: isPOIAndAirportTabVarB, c: isPOIAndAirportTabVarC },
  } = useGetMVTSelections({
    agentId: POI_AIRPORT_SHUTTLE_ON_HDP.agentId,
    MVTVariants: ['b', 'c'],
    isProvisional: !!enableTest,
  });
  const isPOIAndAirportTab = isPOIAndAirportTestLoaded && isPOIAndAirportTabVarB;
  const isAirportAndPOITab = isPOIAndAirportTestLoaded && isPOIAndAirportTabVarC;
  const poiAndAirportTabOrder = isPOIAndAirportTab ? 1 : isAirportAndPOITab ? 2 : undefined;

  return {
    isPOIAndAirportTab,
    isAirportAndPOITab,
    poiAndAirportTabOrder,
  };
};
