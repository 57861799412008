import { useRef, useEffect, useMemo } from 'react';
import { generateMPAQueryArray } from './utils/generate-mpa-query-array';
import { useAuth } from '@dx-ui/framework-auth-provider';
import { useSmbToggle, useWrappedRouter } from '../../hooks';
import type { ShopMultiPropAvailConstraints } from './utils';
import {
  arrayChunk,
  generateShopMultiPropAvailInputVariables,
  getHashedMPADataFromCache,
  getConstraintsFromCachedData,
} from './utils';
import { useQueries, useQueryClient } from '@tanstack/react-query';
import generateMayBeAvailableHashData from './utils/generate-may-be-available-hash-data';
import { isBrowser } from '@dx-ui/utilities-is-browser';
import { useLocation } from '@dx-ui/framework-location-provider';
import type { ShopMultiPropAvailPointsQuery } from '@dx-ui/queries-dx-shop-search-ui';
import { useAppState } from '../../providers/app-provider';
import { getPageType } from '../../utils';

/**
 *
 * @param {Number} [chunkSize=20] - chunk size for parallel queries
 * @param {Number} [maxSize=150] - max number of ctyhocns to retrieve pricing for
 * @param {ShopMultiPropAvailQueryVariables} variables query variables for shopMultiPropAvail
 */
const useShopMultiPropAvail = ({
  maxSize = 150,
  ctyhocns,
  enabled = true,
  mpaConstraints = false,
}: {
  maxSize?: number;
  ctyhocns?: string[];
  enabled?: boolean;
  mpaConstraints?: boolean;
}) => {
  const { router, safeQueryParams: queryParameters } = useWrappedRouter();
  const { guestInfo, isLoading: isAuthLoading } = useAuth();
  const { country: guestLocationCountry, isLoading: isGuestLocationLoading } = useLocation();
  const queryClient = useQueryClient();
  const { shouldUsePoints, hotelsInBounds } = useAppState();
  const ctyhocnsToFetchPrice = (ctyhocns ? ctyhocns : Object.keys(hotelsInBounds))?.sort();
  const constraintsFromCache = useRef<ShopMultiPropAvailConstraints>({
    isPastDate: false,
    invalidOfferId: false,
    hasPointsRateChange: false,
    hasRateChange: false,
    isOfferNotAvailable: false,
  });
  const { programAccountId } = useSmbToggle();

  //When we route again (query params change) need to reset constraints and whether we have fetched for bounds
  useEffect(() => {
    constraintsFromCache.current = {
      isPastDate: false,
      invalidOfferId: false,
      hasPointsRateChange: false,
      hasRateChange: false,
      isOfferNotAvailable: false,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(queryParameters)]);

  const pageType = getPageType(router.asPath, queryParameters);
  const enableQuery =
    !!ctyhocnsToFetchPrice.length &&
    isBrowser &&
    !isAuthLoading &&
    !isGuestLocationLoading &&
    enabled &&
    router.isReady &&
    pageType.isSearch &&
    !pageType.isPointsExplorer &&
    !queryParameters?.datesFlex;

  const CHUNK_SIZE = 20;

  //format input graph object
  const input = generateShopMultiPropAvailInputVariables({
    guestInfo,
    queryParameters,
    shouldUsePoints,
    guestLocationCountry,
    smbProgramAccountId: programAccountId,
  });

  //get hash table data from mpa cache
  const initialHashedShopMultiPropAvailDataFromCache = getHashedMPADataFromCache(
    queryClient,
    input,
    shouldUsePoints
  );

  const initialHashedKeys = Object.keys(initialHashedShopMultiPropAvailDataFromCache);
  //only allow up to the max of max size ctyhocns to be fetched that had not already been fetched before

  // split ctyhocn array of size maxSize into chunks of size chunkSize
  const chunkedMPA = useMemo(() => {
    if (!enableQuery) return [];
    const ctyhocnToFetchPricingFor =
      ctyhocnsToFetchPrice
        ?.reduce((ctyhocnsToFetch, ctyhocn) => {
          if (!initialHashedKeys.includes(ctyhocn)) ctyhocnsToFetch.push(ctyhocn);
          return ctyhocnsToFetch;
        }, [] as string[])
        .slice(0, maxSize) || [];
    return arrayChunk(ctyhocnToFetchPricingFor, CHUNK_SIZE);
  }, [ctyhocnsToFetchPrice, enableQuery, initialHashedKeys, maxSize]);

  //create an array of query objects. Each object is identical except for contents of ctyhocn array
  const queryArr = enableQuery
    ? generateMPAQueryArray({
        chunkedMPA,
        enabled: enableQuery,
        input,
        language: 'en',
      })
    : [];

  //parallel fetch MPA chunks
  const results = useQueries({ queries: queryArr });

  const { hashedData, isError, isLoading, hasMPACallResolved, constraints } = useMemo(() => {
    //if this is dateFlex or flexibleDates scenario we dont use MPA data
    if (queryParameters?.datesFlex) {
      return {
        hashedData: {} as Record<string, ShopMultiPropAvailPointsQuery['shopMultiPropAvail'][0]>,
        isLoading: false,
        isError: false,
        hasMPACallResolved: false,
      };
    }
    const mayBeAvailableHashData = generateMayBeAvailableHashData(results, chunkedMPA);
    const isLoading = results.some((result) => result.isFetching);
    const hashedMPADataFromCache = {
      ...getHashedMPADataFromCache(queryClient, input, shouldUsePoints),
    };
    const hashedData = { ...hashedMPADataFromCache, ...mayBeAvailableHashData };
    const shouldUpdateConstraints = !isLoading && isBrowser && mpaConstraints;
    if (shouldUpdateConstraints) {
      constraintsFromCache.current = getConstraintsFromCachedData(hashedData);
    }

    return {
      hashedData,
      isError:
        results.some((result) => result.isError) || Object.keys(mayBeAvailableHashData).length > 0,
      isLoading,
      hasMPACallResolved: results.some((result) => result.isSuccess),
      constraints: constraintsFromCache.current,
    };
    // HACK: Not sure a way around this one right now. NHCSEARCH-5242
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    mpaConstraints,
    queryParameters?.datesFlex,
    JSON.stringify(results),
    shouldUsePoints,
    ctyhocns,
  ]);

  return {
    hashedData,
    isLoading,
    isError,
    hasMPACallResolved,
    constraints,
  };
};

export default useShopMultiPropAvail;
