import { useLocalStorage } from 'usehooks-ts';

const REFRESH_TTL_2WEEK = 604800 * 2 * 1000;

export type RecentlyViewedHotel = {
  ctyhocn: string;
  expireDate: number;
};

export function useRecentlyViewed(ctyhocn: string) {
  const [recentlyViewed, setRecentlyViewed] = useLocalStorage<Record<string, RecentlyViewedHotel>>(
    `RecentlyViewed`,
    {},
    {
      initializeWithValue: false,
      serializer: (instance) => {
        if (!instance) {
          return JSON.stringify({
            [ctyhocn]: {
              ctyhocn,
              expireDate: new Date().getTime() + REFRESH_TTL_2WEEK,
            },
          });
        } else {
          return JSON.stringify({
            ...instance,
            [ctyhocn]: {
              ctyhocn,
              expireDate: new Date().getTime() + REFRESH_TTL_2WEEK,
            },
          });
        }
      },
    }
  );

  const showRecentlyViewedChip =
    recentlyViewed[ctyhocn] &&
    new Date(recentlyViewed[ctyhocn]?.expireDate).getTime() - new Date().getTime() > 0;

  return { recentlyViewed, setRecentlyViewed, showRecentlyViewedChip } as const;
}
