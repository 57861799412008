import type { UseQueryResult } from '@tanstack/react-query';
import type {
  ShopMultiPropAvailPointsQuery,
  ShopPropStatusType,
} from '@dx-ui/queries-dx-shop-search-ui';

const generateMayBeAvailableHashData = (
  results: UseQueryResult<ShopMultiPropAvailPointsQuery, unknown>[],
  chunkedMPA: string[][]
) =>
  results.reduce((hashData, chunk, idx) => {
    //if chunk is in error state create a hashMap of ctyhocn entries w/ may be available state
    let mayBeAvailableHashMap: Record<
      string,
      ShopMultiPropAvailPointsQuery['shopMultiPropAvail'][0]
    > = {};
    const MPAChunk = chunkedMPA[idx];
    if (chunk.isError && MPAChunk) {
      mayBeAvailableHashMap = MPAChunk.reduce((hashMap, ctyhocn) => {
        hashMap[ctyhocn] = {
          ctyhocn,
          notifications: [],
          summary: {
            status: {
              type: 'MAY_BE_AVAILABLE' as ShopPropStatusType,
            },
          },
        };
        return hashMap;
      }, {} as Record<string, ShopMultiPropAvailPointsQuery['shopMultiPropAvail'][0]>);
    }
    return { ...hashData, ...mayBeAvailableHashMap };
  }, {} as Record<string, ShopMultiPropAvailPointsQuery['shopMultiPropAvail'][0]>);

export default generateMayBeAvailableHashData;
