import { Dialog } from '@dx-ui/osc-dialog-v2';
import { useTranslation } from 'next-i18next';
import { MonthSlider } from './month-slider';
import { addMonths, format, startOfMonth } from 'date-fns';
import { useGetFlexDatesInfo } from './hooks/use-get-flex-dates-info';
import { useWrappedRouter } from '../../../hooks';
import PriceCalendar from './price-calendar';
import { FlexDatesModalFooter } from './flex-dates-modal-footer';
import { useEffect, useMemo, useState } from 'react';
import { DEFAULT_LANGUAGE } from '@dx-ui/utilities-dates';
import { env } from '../../../constants';

type FlexDatesModalProps = {
  ctyhocn: string;
  hotelName?: string;
  isOpen: boolean;
  onClose: () => void;
};

export const FlexDatesModal = ({
  isOpen = false,
  onClose,
  ctyhocn,
  hotelName,
}: FlexDatesModalProps) => {
  const { t } = useTranslation('rate-name-cta');
  const { router } = useWrappedRouter();
  const [selectedDate, setSelectedDate] = useState(startOfMonth(new Date()));
  const [selectedPriceDate, setSelectedPriceDate] = useState<Date | null>(null);
  const startDate = format(selectedDate, 'yyyy-MM-dd');
  const {
    dailyPrice,
    isFetching: isPriceLoading,
    statusCode,
    shouldUsePoints,
  } = useGetFlexDatesInfo({
    arrivalDate: startDate,
    ctyhocn: ctyhocn || '',
    enabled: isOpen,
  });

  useEffect(() => {
    if (isOpen) {
      setSelectedPriceDate(null);
      setSelectedDate(startOfMonth(new Date()));
    }
  }, [isOpen]);

  const handleMonthTabChange = (index: number) => {
    setSelectedPriceDate(null);
    const currDate = addMonths(new Date(), index);
    setSelectedDate(startOfMonth(currDate));
  };

  const handleDaySelection = (day?: Date) => {
    if (day) setSelectedPriceDate(day);
  };

  const handleOnClose = () => {
    setSelectedPriceDate(null);
    if (onClose) onClose();
  };

  const rateInfo = useMemo(() => {
    let rateInfo = null;
    if (selectedPriceDate) {
      const priceDateAsString = format(selectedPriceDate, 'yyyy-MM-dd');
      rateInfo = dailyPrice[priceDateAsString];
    }
    return rateInfo;
  }, [dailyPrice, selectedPriceDate]);
  const bookFlexDatesPage = `${env.OHW_BASE_URL}${
    router.locale || 'en'
  }/book/reservation/flexibledates/?ctyhocn=${ctyhocn}`;
  return (
    <Dialog
      className="px-2"
      isOpen={isOpen}
      onDismiss={handleOnClose}
      size="2xl"
      title={t('flexDatesModal.title')}
    >
      <div className="space-y-2 pb-3 text-sm">
        <div className="font-bold"> {hotelName}</div>
        <div>{t('flexDatesModal.showingBestPriceFor')}</div>
        {!shouldUsePoints ? (
          <div className="font-semibold">{t('flexDatesModal.priceIncludesFee')}</div>
        ) : null}
        <div className="font-bold">
          <a href={bookFlexDatesPage} target="_blank" rel="noreferrer">
            <span className="text-primary hover:text-primary-alt whitespace-nowrap">
              {t('flexDatesModal.exploreAllFilterAndSearch')}
            </span>
            <span aria-hidden>&nbsp;&gt;</span>
          </a>
        </div>
      </div>

      <div className="border-border border-y-2">
        <MonthSlider
          ctyhocn={ctyhocn}
          onMonthChange={(monthTabIndex) => handleMonthTabChange(monthTabIndex)}
        >
          <div>
            <PriceCalendar
              month={selectedDate}
              maxDays={365}
              onDayChange={(day) => handleDaySelection(day)}
              locale={(router.locale || DEFAULT_LANGUAGE) as unknown as Intl.Locale}
              onFocusedDayChange={() => {}}
              dailyPrice={dailyPrice}
              isPriceLoading={isPriceLoading}
              shouldUsePoints={shouldUsePoints}
              statusCode={statusCode}
            />
          </div>
        </MonthSlider>
      </div>
      <FlexDatesModalFooter
        rateInfo={rateInfo}
        onCancel={handleOnClose}
        shouldUsePoints={shouldUsePoints}
        statusCode={statusCode}
        ctyhocn={ctyhocn}
      />
    </Dialog>
  );
};
