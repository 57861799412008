import cx from 'classnames';
import { useTranslation } from 'next-i18next';
import { useMediaQuery } from 'usehooks-ts';
import { INTERNATIONAL_BOOKING_NUMBER, UP_TO_LG_BUTTON_MAX_STRING_LENGTH } from '../../constants';
import { InfoPopup } from '@dx-ui/osc-info-popup';
import { getRateInfoTextClassName } from '../hotel-card/utils/hotel-info-utils';
import Icon from '@dx-ui/osc-icon';
import * as React from 'react';
import { useFeatureToggleConfig, useWrappedRouter } from '../../hooks';
import { useSMBContextLocalStorage } from '@dx-ui/utilities-use-smb-context-local-storage';
import IneligibleHotelModal from './ineligible-hotel-modal';
import { FlexDatesModal } from './flex-dates/flex-dates-modal';

export type RateMessage = {
  className?: string;
  hasColumnLayout?: boolean;
  isCaption?: boolean;
  isCompare?: boolean;
  screenReaderText?: string;
  text?: string;
  type?: 'DiamondRate' | 'UsePoints' | 'amexDisclaimer' | undefined;
  textType?: 'prefix' | 'heading' | 'suffix' | undefined;
  infoIconOrPopup?: { align: 'left' | 'right'; id?: string; infoText?: string; isIcon?: boolean };
};
export type RateInfoMessage = {
  className?: string;
  isCaption?: boolean;
  isCompare?: boolean;
  prefix?: RateMessage;
  hasColumnLayout?: boolean;
  heading: RateMessage;
  suffix?: RateMessage;
  screenReaderText?: string;
  type?: 'DiamondRate' | 'UsePoints' | 'amexDisclaimer' | undefined;
};

type RateNameCTAProps = {
  hasColumnLayout?: boolean;
  ctaHref?: string;
  ctaLabel?: RateMessage;
  ctaMessage?: RateInfoMessage;
  customRateInfo?: {
    [key: string]: string | boolean;
  };
  'data-testid'?: string;
  isLoading?: boolean;
  rateMessages?: RateInfoMessage[];
  onRateNameCTAClick?: () => void;
  // NHCSEARCH-5197 MVT
  onHDPClick?: () => void;
  isHDP?: boolean;
};

const NotBookableInfo = ({
  hasColumnLayout,
  phoneNumber,
}: {
  hasColumnLayout?: boolean;
  phoneNumber?: string;
}) => {
  const { t } = useTranslation('rate-name-cta');
  return phoneNumber ? (
    <div
      className={cx('flex flex-col', { 'mt-auto h-full items-end justify-end': hasColumnLayout })}
    >
      <p className="font-semibold">{t('notBookable')}</p>
      <p className="text-sm font-medium">
        <span>{`${t('tollFree')} `}</span>
        <a className="text-primary" href={`tel:${phoneNumber}`}>
          {phoneNumber}
        </a>
      </p>
      <p className="text-sm font-medium">
        <span>{t('international', { INTERNATIONAL_BOOKING_NUMBER })}</span>
        <a className="text-primary" href={`tel:${INTERNATIONAL_BOOKING_NUMBER}`}>
          {INTERNATIONAL_BOOKING_NUMBER}
        </a>
      </p>
    </div>
  ) : null;
};
const ScreenReaderText = ({ srText }: { srText?: string }) => {
  return srText ? (
    <span className="sr-only">
      <span>{srText}</span>
    </span>
  ) : null;
};

const InfoPopupOrIcon = ({ isIcon, infoText }: { isIcon?: boolean; infoText?: string }) => {
  const { t } = useTranslation('rate-name-cta');
  if (isIcon)
    return (
      <div className="mt-[-0.1875rem] self-start">
        <span className="sr-only">{infoText || t('moreInfo')}</span>
        <Icon name="info-circle" variant="regular" size="sm" />
      </div>
    );
  return infoText ? (
    <InfoPopup>
      <span className="w-56 text-left">{infoText}</span>
    </InfoPopup>
  ) : null;
};
const RateInfoText = ({
  className,
  text,
  hasColumnLayout,
  isCaption = false,
  isCompare = false,
  type,
  textType,
  infoIconOrPopup,
}: RateMessage) => {
  const textClassName = getRateInfoTextClassName({
    className,
    hasColumnLayout,
    isCaption,
    isCompare,
    type,
    textType,
  });
  const rateHeadingTestId = isCaption && textType === 'heading' ? 'rateItem' : undefined;
  const hasLeftIcon = infoIconOrPopup && infoIconOrPopup?.align === 'left';
  return text ? (
    <div
      className={cx('flex space-x-1', {
        'items-center': !hasLeftIcon,
        'items-start': hasLeftIcon,
      })}
    >
      {hasLeftIcon ? (
        <InfoPopupOrIcon isIcon={!!infoIconOrPopup.isIcon} infoText={infoIconOrPopup.infoText} />
      ) : null}
      <span className={textClassName} data-testid={rateHeadingTestId}>
        {text}
      </span>
      {infoIconOrPopup && infoIconOrPopup?.align === 'right' ? (
        <InfoPopupOrIcon isIcon={!!infoIconOrPopup.isIcon} infoText={infoIconOrPopup.infoText} />
      ) : null}
    </div>
  ) : null;
};

const RateMessage = ({
  className,
  hasColumnLayout,
  isCaption = false,
  isCompare = false,
  prefix,
  heading,
  suffix,
  screenReaderText,
  type,
}: RateInfoMessage) => {
  if (!heading) return null;
  return (
    <div
      className={cx(
        'flex flex-wrap justify-end space-x-1 rtl:flex-row-reverse rtl:justify-start rtl:text-left',
        className
      )}
    >
      <RateInfoText
        className="text-xs"
        text={prefix?.text}
        infoIconOrPopup={prefix?.infoIconOrPopup}
        isCaption={isCaption}
        isCompare={isCompare}
        type={type}
        textType="prefix"
      />
      <RateInfoText
        className={heading?.className}
        hasColumnLayout={hasColumnLayout}
        text={heading?.text}
        infoIconOrPopup={heading?.infoIconOrPopup}
        isCaption={isCaption}
        isCompare={isCompare}
        type={type}
        textType="heading"
      />
      <RateInfoText
        className="text-xs"
        text={suffix?.text}
        infoIconOrPopup={suffix?.infoIconOrPopup}
        isCaption={isCaption}
        isCompare={isCompare}
        type={type}
        textType="suffix"
      />
      <ScreenReaderText srText={screenReaderText} />
    </div>
  );
};

export const RateInfoMessages = ({
  hasColumnLayout,
  rateMessages,
  isHDPWithoutCTA,
  isCompare,
}: {
  hasColumnLayout?: boolean;
  rateMessages?: RateInfoMessage[];
  isHDPWithoutCTA?: boolean;
  isCompare?: boolean;
}) => {
  if (!rateMessages?.length) return null;
  return (
    <div
      className={cx('flex flex-col', {
        'mb-1': hasColumnLayout,
        'max-sm:items-center': isHDPWithoutCTA,
        'items-start': isCompare,
        'items-end': !isCompare,
      })}
    >
      {rateMessages?.map((rateMessage, index) =>
        rateMessage ? (
          <RateMessage
            {...rateMessage}
            hasColumnLayout={hasColumnLayout}
            isCaption={index === 0}
            isCompare={isCompare}
            key={`${index + 1}-${rateMessage.heading.text}`}
          />
        ) : null
      )}
    </div>
  );
};

const RateInfoCTAButton = ({
  ctaHref,
  ctaLabel,
  ctaMessage,
  customRateInfo,
  onHDPClick,
  onRateNameCTAClick,
  isHDP,
}: RateNameCTAProps) => {
  const isMobileDevice = useMediaQuery('(max-width: 640px)');
  const { enabled: isSMBContextSwitchingEnabled } = useFeatureToggleConfig('WEBPLAT-551');
  const { isSMBContextSet, smbContext } = useSMBContextLocalStorage(isSMBContextSwitchingEnabled);
  const [ctaClick, setCTAClick] = React.useState(false);
  const isIneligibleHotel =
    !!customRateInfo?.isPartnerBrand && isSMBContextSet && smbContext === 'business';

  const [ctaClickForFlexDatesModal, setCTAClickForFlexDatesModal] = React.useState(false);
  const { router } = useWrappedRouter();

  const showFlexDatesModals = ctaClickForFlexDatesModal && !isHDP;
  const isLocationsPage = router.asPath.includes('/locations/');

  let btnTarget: string | undefined;
  let btnHref: string | undefined = '#';
  if ((!isLocationsPage || !!isHDP) && !isIneligibleHotel) {
    btnHref = ctaHref;
    if (!isMobileDevice) btnTarget = '_blank';
  }

  const handleRateNameCTAClick = () => {
    onHDPClick?.();
    if (!btnTarget) {
      if (isIneligibleHotel) setCTAClick(!ctaClick);
      else setCTAClickForFlexDatesModal(!ctaClickForFlexDatesModal);
    }
    onRateNameCTAClick?.();
  };

  const onContinueIneligibleHotelModal = () => {
    setCTAClick(false);
    window.open(ctaHref, '_blank');
  };

  const onCloseIneligibleHotelModal = () => setCTAClick(false);
  const onCloseFlexDatesModal = () => setCTAClickForFlexDatesModal(false);
  return !!ctaLabel && ctaHref ? (
    <>
      <IneligibleHotelModal
        isShowing={ctaClick}
        onClose={onCloseIneligibleHotelModal}
        onContinue={onContinueIneligibleHotelModal}
      />
      <FlexDatesModal
        isOpen={showFlexDatesModals}
        onClose={onCloseFlexDatesModal}
        ctyhocn={customRateInfo?.ctyhocn as string}
        hotelName={customRateInfo?.hotelName as string}
      />

      <div className="flex items-center justify-end">
        <div className="flex flex-col space-x-1">
          <a
            className={cx(
              'btn btn-primary md:btn-base 2xl:btn-xl flex h-10 cursor-pointer  items-center justify-center',
              {
                'btn-tertiary': ctaLabel?.type === 'UsePoints',
                'lg:btn-base':
                  ctaLabel?.text && ctaLabel.text.length > UP_TO_LG_BUTTON_MAX_STRING_LENGTH,
                'lg:btn-lg':
                  ctaLabel?.text && ctaLabel.text.length < UP_TO_LG_BUTTON_MAX_STRING_LENGTH,
              }
            )}
            href={btnHref}
            target={btnTarget}
            rel={btnTarget ? 'noreferrer' : undefined}
            onClick={handleRateNameCTAClick}
          >
            {ctaLabel?.text}
            <ScreenReaderText srText={ctaLabel?.screenReaderText} />
          </a>
          {ctaMessage ? <RateMessage {...ctaMessage} /> : null}
        </div>
      </div>
    </>
  ) : null;
};

export const RateNameCTA = ({
  hasColumnLayout,
  ctaHref,
  ctaLabel,
  ctaMessage,
  rateMessages,
  customRateInfo,
  isLoading,
  isHDP = false,
  onHDPClick,
  onRateNameCTAClick,
  ...props
}: RateNameCTAProps) => {
  const showOneColumn = ctaLabel && !hasColumnLayout;
  if (isLoading) return <RateNameCtaSkeleton hasColumnLayout={hasColumnLayout} />;
  return (
    <div
      className={cx('flex space-x-4', {
        'flex-col justify-end': showOneColumn,
        'items-start lg:items-center': hasColumnLayout,
        'max-sm:justify-between': !!ctaLabel,
        'max-sm:justify-center': !ctaLabel,
      })}
      data-testid={props['data-testid'] ?? 'hotelDetailsInfo'}
    >
      {customRateInfo?.isNotBookable ? (
        <NotBookableInfo
          hasColumnLayout={!hasColumnLayout}
          phoneNumber={customRateInfo?.phoneNumber as string}
        />
      ) : null}
      <RateInfoMessages
        hasColumnLayout={showOneColumn}
        rateMessages={rateMessages}
        isHDPWithoutCTA={isHDP && !ctaLabel}
      />
      <RateInfoCTAButton
        ctaHref={ctaHref}
        ctaLabel={ctaLabel}
        ctaMessage={ctaMessage}
        customRateInfo={customRateInfo}
        isHDP={isHDP}
        onHDPClick={onHDPClick}
        onRateNameCTAClick={onRateNameCTAClick}
      />
    </div>
  );
};

const RateNameCtaSkeleton = ({ hasColumnLayout }: { hasColumnLayout?: boolean }) => (
  <div
    aria-hidden
    className={cx('flex items-end justify-end space-x-1 px-0 py-2 max-sm:flex-col', {
      'flex-col': !hasColumnLayout,
    })}
    data-testid="rateNameCtaSkeleton"
  >
    <div className={cx('flex items-end justify-end max-sm:py-2', { 'flex-col': !hasColumnLayout })}>
      <div className="bg-border-alt mb-1 h-6 w-16 animate-pulse " />
      <div className="bg-border-alt mb-1 h-6 w-24 animate-pulse" />
    </div>
    <div className="bg-border-alt h-8 w-36 animate-pulse rounded pl-2" />
  </div>
);
