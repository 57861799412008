import cx from 'classnames';
import { useTranslation } from 'next-i18next';
import {
  getRateDetails,
  getRoomsPageUrl,
  type FlexDatesShopAvailCalendar,
} from './flex-dates-utils';
import { useMediaQuery } from 'usehooks-ts';
import { useWrappedRouter } from '../../../hooks';
import { useAppState } from '../../../providers/app-provider';
import { DEFAULT_LANGUAGE } from '@dx-ui/utilities-dates';

type FlexDatesModalFooterProps = {
  ctyhocn: string;
  isPriceLoading?: boolean;
  rateInfo?: FlexDatesShopAvailCalendar;
  statusCode?: number | undefined | null;
  shouldUsePoints?: boolean;
  onCancel: () => void;
};

export const FlexDatesModalFooter = ({
  ctyhocn,
  isPriceLoading,
  rateInfo,
  statusCode,
  shouldUsePoints,
  onCancel,
}: FlexDatesModalFooterProps) => {
  const isMobileDevice = useMediaQuery('(max-width: 640px)');
  const { t } = useTranslation('rate-name-cta');
  const { router } = useWrappedRouter();
  const { selectedCurrency } = useAppState();
  const { priceOrPoints, rateName, rateDate } = getRateDetails({
    isPriceFetching: isPriceLoading,
    statusCode,
    shopAvailCalendar: rateInfo,
    shouldUsePoints,
    t,
    locale: router.locale || DEFAULT_LANGUAGE,
  });
  const bookUrl = getRoomsPageUrl({
    ctyhocn,
    currencyCode: selectedCurrency,
    rateInfo,
    shouldUsePoints,
    language: router.locale || DEFAULT_LANGUAGE,
  });

  const handleChooseRoomClick = () => {};

  return (
    <div
      className={cx('bg-bg mb:pb-0 sticky bottom-0 flex justify-end gap-4 px-1 pt-2 md:flex-row', {
        'max-sm:flex-wrap': !!rateInfo,
      })}
      data-testid="flex-dates-modal-footer"
    >
      {rateInfo ? (
        <>
          <div className="flex grow flex-col text-xs">
            <div
              className={cx('font-sans text-sm font-bold', { 'text-tertiary': shouldUsePoints })}
            >
              {priceOrPoints}
            </div>
            <div className="text-text-alt">{t('flexDatesModal.avgForOneNight')}</div>
            <div className="text-xs font-normal">{rateName}</div>
            <div className="text-text-alt">{rateDate}</div>
          </div>
          <div className="flex items-center">
            <a
              type="button"
              className={cx('btn btn-primary btn-xl h-10 w-full', {
                'btn-tertiary': shouldUsePoints,
              })}
              onClick={handleChooseRoomClick}
              href={bookUrl}
              target={!isMobileDevice ? '_blank' : undefined}
              rel="noreferrer"
            >
              {t('flexDatesModal.chooseRoom')}
            </a>
          </div>
        </>
      ) : null}

      <div className="flex items-center max-sm:w-full md:justify-end">
        <button
          type="button"
          className="btn btn-primary-outline btn-xl h-10 w-full"
          onClick={onCancel}
        >
          {t('flexDatesModal.cancel')}
        </button>
      </div>
    </div>
  );
};
