import type {
  ShopMultiPropAvailPointsQuery,
  ShopMultiPropAvailQuery,
} from '@dx-ui/queries-dx-shop-search-ui';

export const isPastDate = (hotel: ShopMultiPropAvailQuery['shopMultiPropAvail'][0]) =>
  hotel.statusCode === 2004;

export const isOfferNotAvailable = (hotel: ShopMultiPropAvailQuery['shopMultiPropAvail'][0]) =>
  !!hotel?.notifications?.find(
    (notification) =>
      notification?.type === 'info' &&
      notification?.subType === 'offer' &&
      notification?.text?.includes('The offer you requested is not available for your stay dates.')
  );

export const isOfferInvalid = (hotel: ShopMultiPropAvailQuery['shopMultiPropAvail'][0]) =>
  !!hotel?.notifications?.find(
    (notification) =>
      notification?.type === 'info' &&
      notification?.subType === 'offer' &&
      notification?.text?.includes('The offer you requested is not valid')
  );

export const checkPricingConstraints = (
  data: Record<string, ShopMultiPropAvailQuery['shopMultiPropAvail'][0]>
) =>
  Object.values(data).reduce(
    (constraints, hotel) => {
      constraints.isPastDate = isPastDate(hotel);
      constraints.invalidOfferId = isOfferInvalid(hotel);
      if (isOfferNotAvailable(hotel)) constraints.offerNotAvailableCount++;
      return constraints;
    },
    {
      isPastDate: false,
      invalidOfferId: false,
      offerNotAvailableCount: 0,
    }
  );

export type ShopMultiPropAvailConstraints = {
  isPastDate: boolean;
  invalidOfferId: boolean;
  hasPointsRateChange: boolean;
  hasRateChange: boolean;
  isOfferNotAvailable: boolean;
};

export const getConstraintsFromCachedData = (
  cachedData: Record<string, ShopMultiPropAvailPointsQuery['shopMultiPropAvail'][0]>
): ShopMultiPropAvailConstraints => {
  const cacheDataVals = Object.values(cachedData);

  //rate change indicators
  const hasRateChange = cacheDataVals.some(
    (pricing) => pricing?.summary?.lowest?.rateChangeIndicator
  );
  const hasPointsRateChange = cacheDataVals.some(
    (pricing) => pricing?.summary?.hhonors?.rateChangeIndicator
  );

  //check pricing constraints (past date, offerId, etc)
  const { isPastDate, invalidOfferId, offerNotAvailableCount } =
    checkPricingConstraints(cachedData);

  //an offer isnt available notification triggers when we 1) actually have pricing values 2) all pricing values return no offer avail status
  const isOfferNotAvailable = Boolean(
    cacheDataVals.length &&
      offerNotAvailableCount > 0 &&
      offerNotAvailableCount ===
        cacheDataVals?.filter((hotel) => hotel?.ctyhocn && hotel?.statusCode === 0).length
  );

  return {
    isPastDate,
    invalidOfferId,
    hasPointsRateChange,
    hasRateChange,
    isOfferNotAvailable,
  };
};
