import { addYears, eachMonthOfInterval, isEqual, parseISO } from 'date-fns';
import type { ShopCalendar } from '@dx-ui/queries-dx-shop-search-ui';
import type { Locale } from '@dx-ui/framework-uri-builder';
import { bookUriBuilder } from '@dx-ui/framework-uri-builder';
import type { TFunction } from 'i18next';
import { DEFAULT_LANGUAGE } from '@dx-ui/utilities-dates';

export type FlexDatesShopAvailCalendar =
  | Pick<ShopCalendar, 'arrivalDate' | 'departureDate' | 'roomRate'>
  | null
  | undefined;

export type FlexDatesRateInfo = {
  ctyhocn?: string;
  isPriceFetching?: boolean;
  shopAvailCalendar?: FlexDatesShopAvailCalendar;
  statusCode?: number | undefined | null;
  shouldUsePoints?: boolean;
  t?: TFunction<['rate-name-cta']>;
  locale?: string;
};

export function getMonthsYearDetails(locale: string) {
  const currentDate = new Date();
  const monthArray = eachMonthOfInterval({
    start: currentDate,
    end: addYears(currentDate, 1),
  });
  return monthArray.map((date) => {
    const isSelected = isEqual(date, currentDate);
    const monthYear = date.toLocaleDateString(locale, {
      month: 'short',
      year: 'numeric',
    });
    const unabbreviatedMonthYear = date.toLocaleDateString(locale, {
      month: 'long',
      year: 'numeric',
    });
    return {
      date,
      isSelected,
      monthYear,
      unabbreviatedMonthYear,
    };
  });
}

export function getRateDetails({
  isPriceFetching,
  shopAvailCalendar: rateInfo,
  shouldUsePoints,
  statusCode,
  t,
  locale = DEFAULT_LANGUAGE,
}: FlexDatesRateInfo) {
  if (isPriceFetching) {
    return defaultRateDetails();
  }
  if (statusCode !== 0) {
    return t ? rateInfoUnavailable(t) : {};
  }

  const hasNoPriceOrPoints = checkHasNoPriceOrPoints({
    shopAvailCalendar: rateInfo,
    shouldUsePoints,
    statusCode,
  });
  const priceOrPoints = getPriceOrPoints({
    isPriceFetching,
    shopAvailCalendar: rateInfo,
    statusCode,
    shouldUsePoints,
  });
  const pointsAbbr = getPointsAbbr({
    isPriceFetching,
    shopAvailCalendar: rateInfo,
    statusCode,
    shouldUsePoints,
  });

  const rateName = getRateName({ shopAvailCalendar: rateInfo, statusCode });
  const rateDate = getRateDate({ shopAvailCalendar: rateInfo, statusCode, locale });

  const calendarAriaText = t
    ? getCalendarAriaText(t, priceOrPoints, shouldUsePoints, rateInfo)
    : '';

  return { calendarAriaText, hasNoPriceOrPoints, priceOrPoints, pointsAbbr, rateName, rateDate };
}
function defaultRateDetails() {
  return {
    priceOrPoints: '',
    pointsAbbr: '',
    rateName: '',
    rateDate: '',
    hasNoPriceOrPoints: false,
    calendarAriaText: '',
  };
}
function rateInfoUnavailable(t: TFunction<['rate-name-cta']>) {
  return {
    calendarAriaText: t('flexDatesModal.calendarDaySoldOutRateAriaText'),
    priceOrPoints: t('flexDatesModal.soldOut'),
    pointsAbbr: t('flexDatesModal.soldOut'),
    hasNoPriceOrPoints: true,
    rateName: '',
    rateDate: '',
  };
}
function getPointsAbbr({
  shopAvailCalendar: rateInfo,
  shouldUsePoints,
  statusCode,
}: FlexDatesRateInfo) {
  if (statusCode === 0 && shouldUsePoints) {
    const pointsAvailable = rateInfo?.roomRate?.dailyRmPointsRate;
    return pointsAvailable ? `${pointsAvailable / 1000}K` : '';
  }
  return;
}

function getPriceOrPoints({
  shopAvailCalendar: rateInfo,
  shouldUsePoints,
  statusCode,
}: FlexDatesRateInfo) {
  if (statusCode === 0) {
    return shouldUsePoints
      ? rateInfo?.roomRate?.dailyRmPointsRateFmt || ''
      : rateInfo?.roomRate?.rateAmountFmt || '';
  }
}

function getRateName({ shopAvailCalendar: rateInfo, statusCode }: FlexDatesRateInfo) {
  if (statusCode === 0) return rateInfo?.roomRate?.ratePlan?.ratePlanName || '';
}

function getRateDate({ shopAvailCalendar: rateInfo, statusCode, locale }: FlexDatesRateInfo) {
  if (statusCode === 0) {
    const startDate = rateInfo?.arrivalDate ? parseISO(rateInfo?.arrivalDate) : null;
    const endDate = rateInfo?.departureDate ? parseISO(rateInfo?.departureDate) : null;
    const startMonthDay = startDate?.toLocaleDateString(locale, {
      month: 'short',
      day: 'numeric',
    });
    const endMonthDay = endDate?.toLocaleDateString(locale, {
      month: 'short',
      day: 'numeric',
    });
    return startDate && endDate ? startMonthDay + ' - ' + endMonthDay : '';
  }
}

function checkHasNoPriceOrPoints({
  shopAvailCalendar: rateInfo,
  shouldUsePoints,
  statusCode,
}: FlexDatesRateInfo) {
  if (statusCode === 0) {
    return !(shouldUsePoints
      ? !!rateInfo?.roomRate?.dailyRmPointsRateFmt
      : !!rateInfo?.roomRate?.rateAmount);
  }
}

function getCalendarAriaText(
  t: TFunction<['rate-name-cta']>,
  priceOrPoints?: string,
  shouldUsePoints?: boolean,
  rateInfo?: FlexDatesShopAvailCalendar
) {
  if (priceOrPoints) {
    return shouldUsePoints
      ? t('flexDatesModal.calendarDayPointsRateAriaText', {
          arrivalDate: rateInfo?.arrivalDate,
          departureDate: rateInfo?.departureDate,
          rateAmount: priceOrPoints,
        })
      : t('flexDatesModal.calendarDayPriceRateAriaText', {
          arrivalDate: rateInfo?.arrivalDate,
          departureDate: rateInfo?.departureDate,
          rateAmount: priceOrPoints,
        });
  }
}

export function getRoomsPageUrl({
  ctyhocn,
  currencyCode,
  language,
  rateInfo,
  shouldUsePoints,
}: {
  ctyhocn: string;
  currencyCode?: string | null;
  language: string;
  rateInfo: FlexDatesShopAvailCalendar;
  shouldUsePoints?: boolean;
}) {
  const startDate = rateInfo?.arrivalDate ? parseISO(rateInfo?.arrivalDate) : null;
  const endDate = rateInfo?.departureDate ? parseISO(rateInfo?.departureDate) : null;
  const additionalQS: { [key: string]: string } = {};
  if (currencyCode) additionalQS.displayCurrency = currencyCode;

  return bookUriBuilder({
    page: 'rooms',
    locale: language as keyof Locale,
    urlParams: {
      ctyhocn: ctyhocn || '',
      dates: {
        arrivalDate: startDate,
        departureDate: endDate,
      },
      numRooms: 1,
      rates: {
        redeemPts: shouldUsePoints,
      },
      additionalQS: {
        ...additionalQS,
      },
    },
  });
}
